import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BlockUI } from "primereact/blockui";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useFormik } from "formik";
import * as Yup from "yup";
import RightLayoutBenefits2 from "../../component/RightLayoutBenefits2";
import MessageDialog from "../../component/MessageDialog";
import {
    readInvited,
    readRecommender,
    register1,
    register2,
} from "../../service/home/ClubMemberRegistrationService";
import {
    readCitiesByZipCode,
    readStreetsByZipCodeAndCity,
    // readZipCodes,
} from "../../service/home/AddressService";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Registration.css";
import {
    capitalizeFirstLetters,
    dateOfBirthToFormik,
    formatDateOfBirthFromDate,
    formatPhoneNumberToFormik,
} from "../../util/stringFormat";
import pageTeaserRegistrationImage from "../../asset/page-teaser-registration.png";
import clubMemberBannerSquare from "../../asset/bc-nyj-2025-q1_1000x1000_I.jpg";
import clubMemberBannerWide from "../../asset/bc-nyj-2025-q1_1000x250_I.jpg";

const ClubMemberRegistration: React.FC = () => {
    let navigate = useNavigate();

    const [storedVipNumber, setStoredVipNumber] = useState<string | null>(null);
    const [storedRepNumber, setStoredRepNumber] = useState<string | null>(null);
    const [storedInvitationId, setStoredInvitationId] = useState<string | null>(
        null
    );
    const [vipNumber, setVipNumber] = useState<string | null>(null);
    const [recommender, setRecommender] = useState<string>("");
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // console.log("Registration useEffect is called");
        // readZipCodes().then((response) => setZipCodes(response.data));
        setStoredInvitationId(sessionStorage.getItem("invitation"));
        setStoredVipNumber(sessionStorage.getItem("cm"));
        setStoredRepNumber(sessionStorage.getItem("cc"));
        // readRecommender(sessionStorage.getItem("cm")).then((response) =>
        //     setRecommender(capitalizeFirstLetters(response.data))
        // );
    }, []);
    // }, [city]);

    const [blockedPage, setBlockedPage] = useState<boolean>(false);

    const showBlockedPage = () => {
        setBlockedPage(true);
    };

    const hideBlockedPage = () => {
        setBlockedPage(false);
    };

    // const [zipCodes, setZipCodes] = useState([]);
    const [cities, setCities] = useState<any>([]);
    const [streets, setStreets] = useState<any>([]);
    // const [formData, setFormData] = useState({});

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const maxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 18)
    );
    const minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 122)
    );

    const companyTypes = [
        { label: "Válasszon cégformát!", value: "" },
        { label: "Ec. - Egyéni cég", value: "EC." },
        { label: "Ev. - Egyéni vállalkozás", value: "EV." },
        { label: "Bt. - Betéti társaság", value: "BT." },
        { label: "Kft. - Korlátolt Felelősségű Társaság", value: "KFT." },
        { label: "Nyrt. - Nyílt részvénytársaság", value: "NYRT." },
        { label: "Zrt. - Zárt részvénytársaság", value: "ZRT." },
        { label: "Egyesület", value: "EGY." },
        { label: "Szövetkezet", value: "SZVT." },
        { label: "Nonprofit szervezet", value: "NPF." },
        { label: "Egyéb", value: "EGYB." },
    ];

    const onZip1Change = (e: any) => {
        let zipValue = "";
        if (e.value && e.value !== "") {
            zipValue = e.value;
        } else if (e && e !== "") {
            zipValue = e;
        }

        if (zipValue !== "") {
            if (zipValue.replace("_", "").length === 4) {
                formik1.setFieldValue("zip1", zipValue);
                readCitiesByZipCode(zipValue).then((response) => {
                    setCities(response.data);
                    setStreets([]);
                    if (response.data.length === 1) {
                        modifyCity1(response.data[0].label);
                        readStreetsByZipCodeAndCity(
                            zipValue,
                            response.data[0].label
                        ).then((response) => {
                            setStreets(response.data);
                        });
                    } else if (response.data.length > 1) {
                    }
                });
            } else {
                setCities([]);
                setStreets([]);
            }
        } else {
            setCities([]);
            setStreets([]);
        }
    };

    const onZip2Change = (e: any) => {
        let zipValue = "";
        if (e.value && e.value !== "") {
            zipValue = e.value;
        } else if (e && e !== "") {
            zipValue = e;
        }

        if (zipValue !== "") {
            if (zipValue.replace("_", "").length === 4) {
                formik2.setFieldValue("zip2", zipValue);
                readCitiesByZipCode(zipValue).then((response) => {
                    setCities(response.data);
                    setStreets([]);
                    if (response.data.length === 1) {
                        modifyCity2(response.data[0].label);
                        readStreetsByZipCodeAndCity(
                            zipValue,
                            response.data[0].label
                        ).then((response) => {
                            setStreets(response.data);
                        });
                    } else if (response.data.length > 1) {
                    }
                });
            } else {
                setCities([]);
                setStreets([]);
            }
        } else {
            setCities([]);
            setStreets([]);
        }
    };

    const setCity1 = (zipCode: string, city: string) => {
        formik1.setFieldValue("city1", city);
        setStreets([]);
        readStreetsByZipCodeAndCity(zipCode, city).then((response) =>
            setStreets(response.data)
        );
    };

    const setCity2 = (zipCode: string, city: string) => {
        formik2.setFieldValue("city2", city);
        setStreets([]);
        readStreetsByZipCodeAndCity(zipCode, city).then((response) =>
            setStreets(response.data)
        );
    };

    const onCity1Change = (e: { value: any }) => {
        formik1.setFieldValue("city1", e.value);
        setStreets([]);
        readStreetsByZipCodeAndCity(
            formik1.getFieldProps("zip1").value,
            e.value
        ).then((response) => setStreets(response.data));
    };

    const onCity2Change = (e: { value: any }) => {
        formik2.setFieldValue("city2", e.value);
        setStreets([]);
        readStreetsByZipCodeAndCity(
            formik2.getFieldProps("zip2").value,
            e.value
        ).then((response) => setStreets(response.data));
    };

    const modifyCity1 = (s: string) => {
        formik1.setFieldValue("city1", s);
    };

    const modifyCity2 = (s: string) => {
        formik2.setFieldValue("city2", s);
    };

    const formik1 = useFormik({
        initialValues: {
            lastName: "",
            firstName: "",
            // dateOfBirth: "",
            dateOfBirth: null,
            zip1: "",
            city1: "",
            street1: "",
            houseNumber1: "",
            email1: "",
            telephone1: null,
            mobile1: "",
            accepted1: false,
            password1: "",
            passwordAgain1: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            lastName: Yup.string()
                .min(2, "Vezetéknévnek minimum 2 karakternek kell lenni!")
                .required("Kötelező kitölteni!"),
            firstName: Yup.string()
                .min(3, "Keresztnévnek minimum 3 karakternek kell lenni!")
                .required("Kötelező kitölteni!"),
            dateOfBirth: Yup.date()
                // .nullable(true)
                .min(minDate)
                .max(maxDate)
                .required("Kötelező kitölteni!"),
            // dateOfBirth: Yup.string().required("Kötelező kitölteni!"),
            zip1: Yup.string().required("Kötelező kitölteni!"),
            city1: Yup.string().required("Kötelező kitölteni!"),
            street1: Yup.string().required("Kötelező kitölteni!"),
            houseNumber1: Yup.string().required("Kötelező kitölteni!"),
            email1: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
            telephone1: Yup.string().nullable(true),
            mobile1: Yup.string()
                .required("Kötelező kitölteni!")
                .test(
                    "Mobil szám teszt",
                    "Érvénytelen mobil szám",
                    function (val) {
                        if (
                            val &&
                            val.length > 0 &&
                            (val.includes("+36(20)") ||
                                val.includes("+36(30)") ||
                                val.includes("+36(31)") ||
                                val.includes("+36(50)") ||
                                val.includes("+36(60)") ||
                                val.includes("+36(70)"))
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                ),
            accepted1: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
            password1: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                // .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%^/§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű"),
            passwordAgain1: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                // .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%^/§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű")
                // .oneOf([Yup.ref("password1")], "Különböző jelszó")
                .test("Compare test", "Különböző jelszó", function (val) {
                    const pw = formik1.getFieldProps("password1");
                    if (val === pw.value || (!val && pw.value.length === 0)) {
                        return true;
                    } else {
                        return false;
                    }
                }),
            // .when("password1", {
            //     is: (val: string) => (val ? true : false),
            //     then: Yup.string().oneOf(
            //         [Yup.ref("password1")],
            //         "Különböző jelszó"
            //     ),
            // }),
        }),
        onSubmit: (values) => {
            // setFormData(values);
            // console.log(values);
            // alert(JSON.stringify(values, null, 2));
            showBlockedPage();
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            register1(
                values,
                storedVipNumber,
                storedRepNumber,
                vipNumber,
                storedInvitationId
            ).then(
                (response) => {
                    // console.log(response);
                    formik1.resetForm();
                    formik2.resetForm();
                    localStorage.removeItem("invitation");
                    hideBlockedPage();
                    setMessageDialogError(false);
                    setMessageDialogHeader(
                        "Sikeres regisztráció! Utolsó lépés!"
                    );
                    setMessageDialogBody(
                        "Kérem ellenőrizze az email fiókját és fejezze be a regisztrációt a validációs linkre kattintva."
                    );
                    //TODO ellenőrizni
                    setMessageDialogNavigateAction("../login");
                    setMessageDialogShow(Date.now());
                },
                (error) => {
                    hideBlockedPage();
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessageDialogError(true);
                    setMessageDialogHeader("Regisztrációs hiba!");
                    if (responseMessage === "Network Error") {
                        setMessageDialogBody(
                            "Hálozati hiba, kérem próbáljon meg pár perc múlva regisztrálni."
                        );
                    } else {
                        setMessageDialogBody(responseMessage);
                    }
                    setMessageDialogNavigateAction("");
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    const formik2 = useFormik({
        initialValues: {
            companyName: "",
            companyType: "",
            vatNumber: "",
            zip2: "",
            city2: "",
            street2: "",
            houseNumber2: "",
            email2: "",
            telephone2: null,
            mobile2: "",
            accepted2: false,
            password2: "",
            passwordAgain2: "",
        },
        validationSchema: Yup.object({
            companyName: Yup.string().required("Kötelező kitölteni!"),
            companyType: Yup.string().required("Kötelező kitölteni!"),
            vatNumber: Yup.string().required("Kötelező kitölteni!"),
            zip2: Yup.string().required("Kötelező kitölteni!"),
            city2: Yup.string().required("Kötelező kitölteni!"),
            street2: Yup.string().required("Kötelező kitölteni!"),
            email2: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
            telephone2: Yup.string().nullable(true),
            mobile2: Yup.string()
                .required("Kötelező kitölteni!")
                .test(
                    "Mobil szám teszt",
                    "Érvénytelen mobil szám",
                    function (val) {
                        if (
                            val &&
                            val.length > 0 &&
                            (val.includes("+36(20)") ||
                                val.includes("+36(30)") ||
                                val.includes("+36(31)") ||
                                val.includes("+36(50)") ||
                                val.includes("+36(60)") ||
                                val.includes("+36(70)"))
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                ),
            accepted2: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
            password2: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                // .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%^/§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű"),
            passwordAgain2: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                // .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%^§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű")
                .test("Compare test", "Különböző jelszó", function (val) {
                    const pw = formik2.getFieldProps("password2");
                    if (val === pw.value || (!val && pw.value.length === 0)) {
                        return true;
                    } else {
                        return false;
                    }
                }),
            // .when("password2", {
            //     is: (val: string) => (val && val.length > 0 ? true : false),
            //     then: Yup.string().oneOf(
            //         [Yup.ref("password2")],
            //         "Különböző jelszó"
            //     ),
            // }),
        }),
        onSubmit: (values) => {
            // setFormData(values);
            //alert(JSON.stringify(values, null, 2));
            showBlockedPage();
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            register2(
                values,
                storedVipNumber,
                storedRepNumber,
                vipNumber,
                storedInvitationId
            ).then(
                (response) => {
                    //console.log(response);
                    formik1.resetForm();
                    formik2.resetForm();
                    localStorage.removeItem("invitation");
                    hideBlockedPage();
                    setMessageDialogError(false);
                    setMessageDialogHeader(
                        "Sikeres regisztráció! Utolsó lépés!"
                    );
                    setMessageDialogBody(
                        "Kérem ellenőrizze az email fiókját és fejezze be a regisztrációt a validációs linkre kattintva."
                    );
                    //TODO ellenőrizni
                    setMessageDialogNavigateAction("../login");
                    setMessageDialogShow(Date.now());
                },
                (error) => {
                    hideBlockedPage();
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessageDialogError(true);
                    setMessageDialogHeader("Regisztrációs hiba!");
                    if (responseMessage === "Network Error") {
                        setMessageDialogBody(
                            "Hálozati hiba, kérem próbáljon meg pár perc múlva regisztrálni."
                        );
                    } else {
                        setMessageDialogBody(responseMessage);
                    }
                    setMessageDialogNavigateAction("");
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    useEffect(() => {
        if (storedInvitationId !== null) {
            readInvited(storedInvitationId).then(
                (response) => {
                    //TODO ha pending, akkor hibaüzenet
                    console.log(response.data);
                    if (response.data.invitationId) {
                        setStoredInvitationId(response.data.invitationId);
                    }
                    if (response.data.vipNumber) {
                        setVipNumber(response.data.vipNumber);
                    }
                    if (response.data.receivedRecommenderName) {
                        setRecommender(
                            capitalizeFirstLetters(
                                response.data.receivedRecommenderName
                            )
                        );
                    }
                    if (response.data.receivedRecommenderRepresentativeNumber) {
                        setStoredRepNumber(
                            response.data
                                .receivedRecommenderRepresentativeNumber
                        );
                    }
                    if (response.data.receivedRecommenderVipNumber) {
                        setStoredVipNumber(
                            response.data.receivedRecommenderVipNumber
                        );
                    }
                    if (
                        response.data.companyName ||
                        response.data.companyType ||
                        response.data.vatNumber
                    ) {
                        setActiveIndex(1);
                    }
                    if (response.data.zip) {
                        onZip1Change(response.data.zip);
                        onZip2Change(response.data.zip);
                    }
                    if (response.data.lastName) {
                        formik1.setFieldValue(
                            "lastName",
                            capitalizeFirstLetters(response.data.lastName)
                        );
                    }
                    if (response.data.firstName) {
                        formik1.setFieldValue(
                            "firstName",
                            capitalizeFirstLetters(response.data.firstName)
                        );
                    }
                    if (response.data.dateOfBirth) {
                        formik1.setFieldValue(
                            "dateOfBirth",
                            dateOfBirthToFormik(response.data.dateOfBirth)
                        );
                    }
                    if (response.data.zip && response.data.city) {
                        setCity1(response.data.zip, response.data.city);
                        setCity2(response.data.zip, response.data.city);
                    } else if (!response.data.zip && response.data.city) {
                        //shouldn't be here
                        onCity1Change({
                            value: response.data.city,
                        });
                        onCity2Change({
                            value: response.data.city,
                        });
                    }
                    if (response.data.houseNumber) {
                        formik1.setFieldValue(
                            "houseNumber1",
                            response.data.houseNumber
                        );
                        formik2.setFieldValue(
                            "houseNumber2",
                            response.data.houseNumber
                        );
                    }
                    if (response.data.mobile) {
                        formik1.setFieldValue(
                            "mobile1",
                            formatPhoneNumberToFormik(response.data.mobile)
                        );
                        formik2.setFieldValue(
                            "mobile2",
                            formatPhoneNumberToFormik(response.data.mobile)
                        );
                    }
                    if (response.data.telephone) {
                        formik1.setFieldValue(
                            "telephone1",
                            response.data.telephone
                        );
                        formik2.setFieldValue(
                            "telephone2",
                            response.data.telephone
                        );
                    }
                    if (response.data.email) {
                        formik1.setFieldValue("email1", response.data.email);
                        formik2.setFieldValue("email2", response.data.email);
                    }
                    if (response.data.companyName) {
                        formik2.setFieldValue(
                            "companyName",
                            response.data.companyName
                        );
                    }
                    if (response.data.companyType) {
                        formik2.setFieldValue(
                            "companyType",
                            response.data.companyType
                        );
                    }
                    if (response.data.vatNumber) {
                        formik2.setFieldValue(
                            "vatNumber",
                            response.data.vatNumber
                        );
                    }
                    if (response.data.street) {
                        formik1.setFieldValue("street1", response.data.street);
                        formik2.setFieldValue("street2", response.data.street);
                    }
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
        } else if (storedVipNumber !== null && storedInvitationId === null) {
            setLoading(false);
            readRecommender(sessionStorage.getItem("cm")).then((response) =>
                setRecommender(capitalizeFirstLetters(response.data))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedVipNumber, storedInvitationId]);

    const header = <h6>Adja meg a jelszavát!</h6>;
    const footer = (
        <Fragment>
            <Divider />
            <p className="mt-2">Javaslatok</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>Legalább 8 karakter hosszú</li>
                <li>Legalább egy nagybetű</li>
                <li>Legalább egy kisbetű</li>
                <li>Legalább egy szám</li>
                {/* <li>Legalább egy speciális karakter @#$%^&*?_~.,;=</li> */}
            </ul>
        </Fragment>
    );

    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                    messageDialogNavigateAction={messageDialogNavigateAction}
                />
                <BlockUI
                    blocked={blockedPage}
                    className="max-w-screen h-full z-1"
                    fullScreen
                    template={
                        <div
                            className="text-center text-teal-50"
                            style={{ fontSize: "2rem" }}
                        >
                            <i
                                className="pi pi-spin pi-spinner"
                                style={{ fontSize: "3rem" }}
                            />
                            <br />
                            Kérem várjon a regisztráció befejezéséig!
                        </div>
                    }
                />
                <div className="flex flex-wrap shadow-2">
                    <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                        <div className="col-12">
                            <div className="flex align-items-center justify-content-center">
                                <img
                                    className="max-w-full mb-3 border-round-xl fadeinleft animation-duration-500 md:hidden"
                                    src={clubMemberBannerSquare}
                                    alt="ZepterClub nyereményjáték"
                                />
                                <img
                                    className="hidden max-w-full mb-3 border-round-xl fadeinleft animation-duration-500 md:block"
                                    src={clubMemberBannerWide}
                                    alt="ZepterClub nyereményjáték"
                                />
                            </div>
                            <Accordion activeIndex={null}>
                                <AccordionTab
                                    tabIndex={0}
                                    header="🍀 Nyereményjáték 2025. március 31-ig"
                                >
                                    <p>
                                        2025.01.01. – 2025.03.31. közötti
                                        időszakban az alábbi ösztönző
                                        nyereményjátékot vezettük be:
                                        <ol>
                                            <li>
                                                Minden
                                                <strong>
                                                    {" "}
                                                    újonnan belépő klubtag, aki
                                                    a{" "}
                                                    <a
                                                        href="https://zepterclub.hu/login"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        www.zepterclub.hu
                                                    </a>{" "}
                                                    vagy a{" "}
                                                    <a
                                                        href="https://www.zepter.hu/zepterclub"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        www.zepter.hu
                                                    </a>
                                                    &nbsp;felületen keresztül
                                                    csatlakozik új ZepterClub
                                                    tagként{" "}
                                                </strong>
                                                részt vesz egy
                                                nyereményjátékban, ahol{" "}
                                                <strong>
                                                    {" "}
                                                    megnyerhet 1 db{" "}
                                                    <a
                                                        href="https://www.zepter.hu/beauty-wellness/Medolight-BluDoc/Medolight-BluDoc"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Z5L-C Zepter Bioptron
                                                        MedoLight BluDoc
                                                    </a>
                                                    -ot{" "}
                                                </strong>
                                                299.900 Ft értékben.
                                            </li>
                                        </ol>
                                        <i>
                                            Nyereményjáték szabályzat elérhető
                                            az alábbi linken és a zárt
                                            felhasznlói fiókba való
                                            bejelentkezés után az "Információk"
                                            menüpont alatt is megtekinthető.
                                            <br />
                                            <a
                                                href="https://zepterclub.hu/2025-q1-nyeremenyjatek-szabalyzat"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://zepterclub.hu/2025-q1-nyeremenyjatek-szabalyzat
                                            </a>
                                        </i>
                                        <br />
                                        <br />
                                        Sok sikert kívánunk!
                                        <br />
                                        <br />
                                        Zepter Magyarország csapata
                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="col-12 md:col-6 lg:col-5">
                            <img
                                src={pageTeaserRegistrationImage}
                                className="responsive fadeinleft animation-duration-500"
                                alt="zepterclub.hu regisztráció kép"
                            />
                        </div>
                        <div className="col-12 md:col-6 lg:col-7">
                            <div className="text-xl text-black-alpha-90 font-500 mb-3 sm:mt-3">
                                ZepterClub hűségprogramban Regisztráció
                            </div>
                            <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-3">
                                Regisztráljon ingyenesen, kötelezettség mentesen
                                és élvezze a klubtasággal járó előnyöket!
                            </p>
                            <div className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-3">
                                Az Ön ajánlója:{" "}
                                <strong className="pink-500 text-pink-500">
                                    {recommender ? (
                                        recommender
                                    ) : (
                                        <>
                                            <ProgressSpinner
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                }}
                                                strokeWidth="6"
                                                animationDuration="1s"
                                            />
                                        </>
                                    )}
                                </strong>
                            </div>
                            <hr />
                            <p className="text-xl text-teal-500 line-height-3 mt-0 mb-3">
                                <strong>
                                    👉 Amennyiben már klubtagunk, elegendő
                                    jelszót igényelnie.
                                </strong>
                            </p>
                            <div className="flex justify-content-center">
                                <Button
                                    label="Jelszó igénylés"
                                    className="mb-2 w-full md:w-8"
                                    type="button"
                                    onClick={() => {
                                        navigate("../getpassword");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? null : (
                        <div className="mt-0 w-full p-4 surface-card lg:w-6 lg:p-7">
                            <TabView
                                activeIndex={activeIndex}
                                onTabChange={(e) => setActiveIndex(e.index)}
                            >
                                <TabPanel header="MAGÁNSZEMÉLY">
                                    <form
                                        onSubmit={formik1.handleSubmit}
                                        className="p-fluid"
                                    >
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="lastName"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Vezetéknév
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    autoFocus
                                                    id="lastName"
                                                    name="lastName"
                                                    value={
                                                        formik1.values.lastName
                                                    }
                                                    onChange={
                                                        formik1.handleChange
                                                    }
                                                />
                                                {formik1.touched.lastName &&
                                                formik1.errors.lastName ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .lastName
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="firstName"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Keresztnév
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    id="firstName"
                                                    name="firstName"
                                                    value={
                                                        formik1.values.firstName
                                                    }
                                                    onChange={
                                                        formik1.handleChange
                                                    }
                                                />
                                                {formik1.touched.firstName &&
                                                formik1.errors.firstName ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .firstName
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="dateOfBirth"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Születési idő
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="9999.99.99"
                                                    id="dateOfBirth"
                                                    value={
                                                        formik1.values
                                                            .dateOfBirth
                                                            ? formatDateOfBirthFromDate(
                                                                  formik1.values
                                                                      .dateOfBirth
                                                              )
                                                            : ""
                                                    }
                                                    onChange={(val: any) => {
                                                        // console.log(val);
                                                        if (val && val.value) {
                                                            formik1.setFieldValue(
                                                                "dateOfBirth",
                                                                dateOfBirthToFormik(
                                                                    val.value
                                                                )
                                                            );
                                                        }
                                                        // if (val) {
                                                        //     const year =
                                                        //         val.value.substring(
                                                        //             0,
                                                        //             4
                                                        //         );
                                                        //     const month =
                                                        //         val.value.substring(
                                                        //             5,
                                                        //             7
                                                        //         );
                                                        //     const day =
                                                        //         val.value.substring(
                                                        //             8,
                                                        //             10
                                                        //         );
                                                        //     const date =
                                                        //         new Date(
                                                        //             year,
                                                        //             month - 1,
                                                        //             day
                                                        //         );
                                                        //     formik1.setFieldValue(
                                                        //         "dateOfBirth",
                                                        //         date
                                                        //     );
                                                        // }
                                                    }}
                                                />
                                                {formik1.touched.dateOfBirth &&
                                                formik1.errors.dateOfBirth ? (
                                                    <small className="p-error">
                                                        {formik1.errors.dateOfBirth.includes(
                                                            "Invalid"
                                                        )
                                                            ? "Hibás születési dátum"
                                                            : null}
                                                        {formik1.errors.dateOfBirth.includes(
                                                            "Kötelező"
                                                        )
                                                            ? "Kötelező kitölteni!"
                                                            : null}
                                                        {formik1.errors.dateOfBirth.includes(
                                                            "later"
                                                        )
                                                            ? "A születési dátum túl korai"
                                                            : null}
                                                        {formik1.errors.dateOfBirth.includes(
                                                            "earlier "
                                                        )
                                                            ? "Regisztráció 18 éven felülieknek lehetséges"
                                                            : null}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="zip1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Irányítószám
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="9999"
                                                    id="zip1"
                                                    value={formik1.values.zip1}
                                                    // {...formik1.getFieldProps(
                                                    //     "zip1"
                                                    // )}
                                                    onChange={onZip1Change}
                                                />
                                                {formik1.touched.zip1 &&
                                                formik1.errors.zip1 ? (
                                                    <small className="p-error">
                                                        {formik1.errors.zip1}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="city1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Város
                                                </label>
                                                <Dropdown
                                                    id="city1"
                                                    className="w-full mb-3"
                                                    {...formik1.getFieldProps(
                                                        "city1"
                                                    )}
                                                    options={cities}
                                                    onChange={onCity1Change}
                                                    placeholder="Válasszon várost!"
                                                    filter
                                                />
                                                {formik1.touched.city1 &&
                                                formik1.errors.city1 ? (
                                                    <small className="p-error">
                                                        {formik1.errors.city1}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="street1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Cím
                                                </label>
                                                <Dropdown
                                                    id="street1"
                                                    className="w-full mb-3"
                                                    {...formik1.getFieldProps(
                                                        "street1"
                                                    )}
                                                    options={streets}
                                                    placeholder="Válasszon utcát!"
                                                    filter
                                                />
                                                {formik1.touched.street1 &&
                                                formik1.errors.street1 ? (
                                                    <small className="p-error">
                                                        {formik1.errors.street1}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="houseNumber1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Házszám
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    id="houseNumber1"
                                                    name="houseNumber1"
                                                    value={
                                                        formik1.values
                                                            .houseNumber1
                                                    }
                                                    onChange={
                                                        formik1.handleChange
                                                    }
                                                />
                                                {formik1.touched.houseNumber1 &&
                                                formik1.errors.houseNumber1 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .houseNumber1
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="mobile1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Mobil
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="+36(99)999-9999"
                                                    id="mobile1"
                                                    {...formik1.getFieldProps(
                                                        "mobile1"
                                                    )}
                                                />
                                                {formik1.touched.mobile1 &&
                                                formik1.errors.mobile1 ? (
                                                    <small className="p-error">
                                                        {formik1.errors.mobile1}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="telephone1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Telefon
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="+3699999999"
                                                    id="telephone1"
                                                    {...formik1.getFieldProps(
                                                        "telephone1"
                                                    )}
                                                />
                                                {formik1.touched.telephone1 &&
                                                formik1.errors.telephone1 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .telephone1
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="email1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Email
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    id="email1"
                                                    {...formik1.getFieldProps(
                                                        "email1"
                                                    )}
                                                    onBlur={(val: any) => {
                                                        const emailTrim =
                                                            formik1.values.email1
                                                                .trim()
                                                                .toLocaleLowerCase();
                                                        formik1.setFieldValue(
                                                            "email1",
                                                            emailTrim
                                                        );
                                                    }}
                                                />
                                                {formik1.touched.email1 &&
                                                formik1.errors.email1 ? (
                                                    <small className="p-error">
                                                        {formik1.errors.email1}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 text-teal-500 line-height-3 mt-0 mt-0">
                                                Jelszót nem kötelező kitölteni
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="password1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Jelszó
                                                </label>
                                                <Password
                                                    id="password1"
                                                    toggleMask
                                                    className="w-full mb-3"
                                                    {...formik1.getFieldProps(
                                                        "password1"
                                                    )}
                                                    panelClassName="password-panel-right"
                                                    header={header}
                                                    footer={footer}
                                                />
                                                {formik1.touched.password1 &&
                                                formik1.errors.password1 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .password1
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col">
                                                <label
                                                    htmlFor="passwordAgain1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Jelszó ismét
                                                </label>
                                                <Password
                                                    id="passwordAgain1"
                                                    toggleMask
                                                    className="w-full mb-3"
                                                    {...formik1.getFieldProps(
                                                        "passwordAgain1"
                                                    )}
                                                    panelClassName="password-panel-right"
                                                    header={header}
                                                    footer={footer}
                                                />
                                                {formik1.touched
                                                    .passwordAgain1 &&
                                                formik1.errors
                                                    .passwordAgain1 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .passwordAgain1
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div id="password-meter"></div>
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <div className="field-checkbox col">
                                                    <Checkbox
                                                        id="accepted1"
                                                        name="accepted1"
                                                        checked={
                                                            formik1.values
                                                                .accepted1
                                                        }
                                                        onChange={
                                                            formik1.handleChange
                                                        }
                                                    />
                                                    <label htmlFor="accepted1">
                                                        <a
                                                            href="https://www.zepter.hu/Rules/Regulation-BizzClub"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            ZepterClub
                                                            hűségprogram
                                                            szabályzatot
                                                        </a>{" "}
                                                        megismertem és
                                                        elfogadom.
                                                    </label>
                                                </div>
                                                {formik1.touched.accepted1 &&
                                                formik1.errors.accepted1 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik1.errors
                                                                .accepted1
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <Button
                                                    label="Regisztráció"
                                                    className="w-full"
                                                    type="submit"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>

                                <TabPanel header="VÁLLALKOZÁS">
                                    <form
                                        onSubmit={formik2.handleSubmit}
                                        className="p-fluid"
                                    >
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="companyName"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Cégnév
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    autoFocus
                                                    id="companyName"
                                                    name="companyName"
                                                    value={
                                                        formik2.values
                                                            .companyName
                                                    }
                                                    onChange={
                                                        formik2.handleChange
                                                    }
                                                />
                                                {formik2.touched.companyName &&
                                                formik2.errors.companyName ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .companyName
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="companyType"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Cégforma
                                                </label>
                                                <Dropdown
                                                    id="companyType"
                                                    className="w-full mb-3"
                                                    {...formik2.getFieldProps(
                                                        "companyType"
                                                    )}
                                                    options={companyTypes}
                                                    onChange={
                                                        formik2.handleChange
                                                    }
                                                    value={
                                                        formik2.values
                                                            .companyType
                                                    }
                                                    placeholder="Válasszon cégformát!"
                                                />
                                                {formik2.touched.companyType &&
                                                formik2.errors.companyType ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .companyType
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="vatNumber"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Adószám
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="99999999-9-99"
                                                    id="vatNumber"
                                                    value={
                                                        formik2.values.vatNumber
                                                    }
                                                    onChange={
                                                        formik2.handleChange
                                                    }
                                                />
                                                {formik2.touched.vatNumber &&
                                                formik2.errors.vatNumber ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .vatNumber
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="zip2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Irányítószám
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="9999"
                                                    id="zip2"
                                                    {...formik2.getFieldProps(
                                                        "zip2"
                                                    )}
                                                    onChange={onZip2Change}
                                                />
                                                {formik2.touched.zip2 &&
                                                formik2.errors.zip2 ? (
                                                    <small className="p-error">
                                                        {formik2.errors.zip2}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="city2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Város
                                                </label>
                                                <Dropdown
                                                    id="city2"
                                                    className="w-full mb-3"
                                                    {...formik2.getFieldProps(
                                                        "city2"
                                                    )}
                                                    options={cities}
                                                    onChange={onCity2Change}
                                                    placeholder="Válasszon várost!"
                                                    filter
                                                />
                                                {formik2.touched.city2 &&
                                                formik2.errors.city2 ? (
                                                    <small className="p-error">
                                                        {formik2.errors.city2}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="street2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Cím
                                                </label>
                                                <Dropdown
                                                    id="street2"
                                                    className="w-full mb-3"
                                                    {...formik2.getFieldProps(
                                                        "street2"
                                                    )}
                                                    options={streets}
                                                    placeholder="Válasszon utcát!"
                                                    filter
                                                />
                                                {formik2.touched.street2 &&
                                                formik2.errors.street2 ? (
                                                    <small className="p-error">
                                                        {formik2.errors.street2}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="houseNumber1"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Házszám
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    id="houseNumber2"
                                                    name="houseNumber2"
                                                    value={
                                                        formik2.values
                                                            .houseNumber2
                                                    }
                                                    onChange={
                                                        formik2.handleChange
                                                    }
                                                />

                                                {formik2.touched.houseNumber2 &&
                                                formik2.errors.houseNumber2 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .houseNumber2
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label
                                                    htmlFor="email2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Email
                                                </label>
                                                <InputText
                                                    className="w-full mb-3"
                                                    id="email2"
                                                    {...formik2.getFieldProps(
                                                        "email2"
                                                    )}
                                                    onBlur={(val: any) => {
                                                        const emailTrim =
                                                            formik2.values.email2
                                                                .trim()
                                                                .toLocaleLowerCase();
                                                        formik2.setFieldValue(
                                                            "email2",
                                                            emailTrim
                                                        );
                                                    }}
                                                />
                                                {formik2.touched.email2 &&
                                                formik2.errors.email2 ? (
                                                    <small className="p-error">
                                                        {formik2.errors.email2}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="mobile2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Mobil
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="+36(99)999-9999"
                                                    id="mobile2"
                                                    {...formik2.getFieldProps(
                                                        "mobile2"
                                                    )}
                                                />
                                                {formik2.touched.mobile2 &&
                                                formik2.errors.mobile2 ? (
                                                    <small className="p-error">
                                                        {formik2.errors.mobile2}
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="telephone2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Telefon
                                                </label>
                                                <InputMask
                                                    className="w-full mb-3"
                                                    mask="+3699999999"
                                                    id="telephone2"
                                                    {...formik2.getFieldProps(
                                                        "telephone2"
                                                    )}
                                                />

                                                {formik2.touched.telephone2 &&
                                                formik2.errors.telephone2 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .telephone2
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="password2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Jelszó
                                                </label>
                                                <Password
                                                    id="password2"
                                                    toggleMask
                                                    className="w-full mb-3"
                                                    {...formik2.getFieldProps(
                                                        "password2"
                                                    )}
                                                    header={header}
                                                    footer={footer}
                                                />
                                                {formik2.touched.password2 &&
                                                formik2.errors.password2 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .password2
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                            <div className="field col-12 md:col-6">
                                                <label
                                                    htmlFor="passwordAgain2"
                                                    className="block text-900 font-medium mb-2"
                                                >
                                                    Jelszó ismét
                                                </label>
                                                <Password
                                                    id="passwordAgain2"
                                                    toggleMask
                                                    className="w-full mb-3"
                                                    {...formik2.getFieldProps(
                                                        "passwordAgain2"
                                                    )}
                                                    header={header}
                                                    footer={footer}
                                                />
                                                {formik2.touched
                                                    .passwordAgain2 &&
                                                formik2.errors
                                                    .passwordAgain2 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .passwordAgain2
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <div className="field-checkbox col">
                                                    <Checkbox
                                                        id="accepted2"
                                                        name="accepted2"
                                                        checked={
                                                            formik2.values
                                                                .accepted2
                                                        }
                                                        onChange={
                                                            formik2.handleChange
                                                        }
                                                    />
                                                    <label htmlFor="accepted2">
                                                        <a
                                                            href="https://www.zepter.hu/Rules/Regulation-BizzClub"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            ZepterClub
                                                            hűségprogram
                                                            szabályzatot
                                                        </a>{" "}
                                                        megismertem és
                                                        elfogadom.
                                                    </label>
                                                </div>
                                                {formik2.touched.accepted2 &&
                                                formik2.errors.accepted2 ? (
                                                    <small className="p-error">
                                                        {
                                                            formik2.errors
                                                                .accepted2
                                                        }
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <Button
                                                    label="Regisztráció"
                                                    className="w-full"
                                                    type="submit"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                            </TabView>
                        </div>
                    )}
                    <RightLayoutBenefits2 />
                </div>
            </div>
        </>
    );
};

export default ClubMemberRegistration;
